import React from "react";
import * as ChakraUI from "@chakra-ui/react";
import {theme} from "../../Utils/Theme";
import {social_channels} from "../../Utils/Info";

export const Footer = () => {
    return (
        <ChakraUI.Center
            flexDir={"row"}
            bgColor={theme.light_green}
            p={"10px"}
            gap={"5"}
            borderTopWidth={"1px"}
            borderColor={"black"}
            width={"100%"}
            position={"fixed"}
            bottom={0}
            zIndex={10}
        >
            {social_channels.map((channel, index) => (
                <a key={index} href={channel.url} target={"_blank"}>
                    <ChakraUI.Icon as={channel.icon} color={"black"} fontSize={"30px"}/>
                </a>
            ))}
        </ChakraUI.Center>
    )
}
