import {FaLinkedin, FaSquareFacebook, FaSquareInstagram, FaSquareXTwitter, FaTiktok, FaYoutube} from "react-icons/fa6";
import tarwiiga_demo_english from "../Assets/tarwiiga_demo_english.mp4";
import tarwiiga_demo_arabic from "../Assets/tarwiiga_demo_arabic.mp4";
import adgen_demo_en from "../Assets/adgen_demo_en.png";
import adgen_demo_ar from "../Assets/adgen_demo_ar.png";
import chat_demo_en from "../Assets/chat_demo_en.png";
import chat_demo_ar from "../Assets/chat_demo_ar.png";

export const social_channels = [
    {
        icon: FaSquareXTwitter,
        url: "https://x.com/tarwiiga",
    },
    {
        icon: FaSquareFacebook,
        url: "https://facebook.com/tarwiiga",
    },
    {
        icon: FaSquareInstagram,
        url: "https://instagram.com/tarwiiga",
    },
    {
        icon: FaLinkedin,
        url: "https://linkedin.com/company/tarwiiga",
    },
    {
        icon: FaYoutube,
        url: "https://youtube.com/@tarwiiga",
    },
    {
        icon: FaTiktok,
        url: "https://tiktok.com/@tarwiiga",
    },
]

export const call_to_action = {
    title_en: "Talk to Sales",
    title_ar: "تحدث إلى المبيعات",
    url: "https://calendly.com/tarwiiga/sales",
}

export const home_info_en = {
    name: "Tarwiiga",
    headline1: "Create thousands of Google ads using AI",
    headline2: "The perfect solution for advertising agencies and marketers",
    description: "Crafted ads to target your ideal audience and maximize ROI.",
    demo_video: tarwiiga_demo_english
}

export const home_info_ar = {
    name: "تـرويـجـة",
    headline1: "أنشئ آلاف من إعلانات جوجل باستخدام الذكاء الاصطناعي!",
    headline2: "الحل المثالي للوكالات الإعلانية والمسوقين",
    description: "إعلانات مصممة بدقة لاستهداف جمهورك وزيادة العائد على الاستثمار",
    demo_video: tarwiiga_demo_arabic
}

export const adgen_info_en = {
    headline1: "A simple and easy interface",
    headline2: "Enter a website or keywords file and get the ads",
    description: "Support many languages and you can publish them directly to your Google Ads account",
    demo_image: adgen_demo_en
}

export const adgen_info_ar = {
    headline1: "واجهة بسيطة وسهلة",
    headline2: "قم بإدخال موقع الكتروني أو ملف كلمات بحثية واحصل على الإعلانات",
    description: "يدعم جميع اللهجات العربية وتقدر تنشرها على حساب إعلانات جوجل مباشرة",
    demo_image: adgen_demo_ar
}

export const chat_info_en = {
    headline1: "A simple and easy-to-use chat",
    headline2: "A GPT-like chat trained on all articles at Google Ads Help Centre",
    description: "Have a question about Google Ads? Try the chat, and it will answer like a Google Ads expert",
    demo_image: chat_demo_en
}

export const chat_info_ar = {
    headline1: "شات بسيط وسهل الاستخدام",
    headline2: "شات مثل ChatGPT مدرب على جميع مقالات مركز مساعدة إعلانات جوجل",
    description: "عندك سؤال متعلق بإعلانات جوجل؟ جرب الشات وهو هيجاوبك مثل خبير إعلانات جوجل",
    demo_image: chat_demo_ar
}
