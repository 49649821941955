export const LANGUAGES = {
    ENGLISH: "EN",
    ARABIC: "AR",
}

export const languages = [
    LANGUAGES.ENGLISH,
    LANGUAGES.ARABIC,
]

