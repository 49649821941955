import {applyMiddleware, combineReducers, legacy_createStore as createStore} from "redux";
import thunk from "redux-thunk";
import {PageReducer} from "./Page/Reducer";

const rootReducer = combineReducers({
    pageReducer: PageReducer,
})

export const Store = createStore(rootReducer, applyMiddleware(thunk))

export type RootStore = ReturnType<typeof rootReducer>
