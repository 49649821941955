import React from 'react';
import * as ChakraUI from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {RootStore} from "../Data/Store";
import {LANGUAGES} from "../Utils/Types";
import {call_to_action, chat_info_ar, chat_info_en} from "../Utils/Info";
import {theme} from "../Utils/Theme";

export const ChatPage: React.FC = () => {

    const pageState = useSelector((state: RootStore) => state.pageReducer)

    return (
        <>
            {pageState.language === LANGUAGES.ENGLISH
                ? <ChatPageEn/>
                : <ChatPageAr/>
            }
        </>
    );
}

export const ChatPageEn: React.FC = () => {
    return (
        <ChakraUI.Flex flexDir={"column"} pt={"50px"} gap={"5"} dir={"rtl"}>

            <ChakraUI.Center flexDir={"column"} pt={"50px"} gap={"10px"} textAlign={"center"}>
                <ChakraUI.Text fontSize={"40px"} fontWeight={"bold"}>
                    {chat_info_en.headline1}
                </ChakraUI.Text>
                <ChakraUI.Text fontSize={"30px"} fontWeight={"bold"}>
                    {chat_info_en.headline2}
                </ChakraUI.Text>
                <ChakraUI.Text fontSize={"25px"}>
                    {chat_info_en.description}
                </ChakraUI.Text>
            </ChakraUI.Center>

            <ChakraUI.Center>
                <ChakraUI.Button
                    colorScheme={"none"}
                    bgColor={theme.light_green}
                    color={"black"}
                    borderRadius={"10px"}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    px={"30px"}
                    py={"25px"}
                    fontSize={"20px"}
                    fontWeight={"bold"}
                    onClick={() => window.open(call_to_action.url, '_blank')}
                >{call_to_action.title_en}</ChakraUI.Button>
            </ChakraUI.Center>

            <ChakraUI.Center
                w={"80%"}
                mx={"auto"}
                borderWidth={"1px"}
                borderColor={"black"}
                borderRadius={"10px"}
            >
                <ChakraUI.Image src={chat_info_en.demo_image} borderRadius={"10px"}/>
            </ChakraUI.Center>
        </ChakraUI.Flex>
    );
}

export const ChatPageAr: React.FC = () => {
    return (
        <ChakraUI.Flex flexDir={"column"} pt={"50px"} gap={"5"} dir={"rtl"}>

            <ChakraUI.Center flexDir={"column"} pt={"50px"} gap={"10px"} textAlign={"center"}>
                <ChakraUI.Text fontSize={"40px"} fontWeight={"bold"}>
                    {chat_info_ar.headline1}
                </ChakraUI.Text>
                <ChakraUI.Text fontSize={"30px"} fontWeight={"bold"}>
                    {chat_info_ar.headline2}
                </ChakraUI.Text>
                <ChakraUI.Text fontSize={"25px"}>
                    {chat_info_ar.description}
                </ChakraUI.Text>
            </ChakraUI.Center>

            <ChakraUI.Center>
                <ChakraUI.Button
                    colorScheme={"none"}
                    bgColor={theme.light_green}
                    color={"black"}
                    borderRadius={"10px"}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    px={"30px"}
                    py={"25px"}
                    fontSize={"20px"}
                    fontWeight={"bold"}
                    onClick={() => window.open(call_to_action.url, '_blank')}
                >{call_to_action.title_ar}</ChakraUI.Button>
            </ChakraUI.Center>

            <ChakraUI.Center
                w={"80%"}
                mx={"auto"}
                borderWidth={"1px"}
                borderColor={"black"}
                borderRadius={"10px"}
            >
                <ChakraUI.Image src={chat_info_ar.demo_image} borderRadius={"10px"}/>
            </ChakraUI.Center>
        </ChakraUI.Flex>
    );
}
