import {PageType} from "../Data/Page/Actions";

export const PAGE = {
    HOME: "",
    ADGEN: "adgen",
    CHAT: "chat",
}

export const pages: PageType[] = [
    {
        id: PAGE.HOME,
        name_en: "Home",
        name_ar: "الرئيسية",
    },
    {
        id: PAGE.ADGEN,
        name_en: "AdGen",
        name_ar: "صانع الإعلانات",
    },
    {
        id: PAGE.CHAT,
        name_en: "Chat",
        name_ar: "شات",
    },
]

export const getPage = (pages: PageType[], id: string) => {
    const index = pages.findIndex(page => page.id === id)
    return pages[index]
}
