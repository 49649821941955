import React from 'react';
import * as ChakraUI from "@chakra-ui/react";
import {theme} from "../Utils/Theme";
import {useSelector} from "react-redux";
import {RootStore} from "../Data/Store";
import {home_info_en, home_info_ar, call_to_action} from "../Utils/Info";
import {LANGUAGES} from "../Utils/Types";

export const HomePage: React.FC = () => {

    const pageState = useSelector((state: RootStore) => state.pageReducer)

    return (
        <>
            {pageState.language === LANGUAGES.ENGLISH ? <HomePageEn/> : <HomePageAr/>}
        </>
    )
}


export const HomePageEn: React.FC = () => {
    return (
        <ChakraUI.Flex flexDir={"column"} gap={5} pt={"100px"} >

            <ChakraUI.Center flexDir={"column"} gap={"10px"} textAlign={"center"}>
                <ChakraUI.Text fontSize={"50px"} fontWeight={"bold"}>
                    {home_info_en.headline1}
                </ChakraUI.Text>
                <ChakraUI.Text fontSize={"30px"} fontWeight={"bold"}>
                    {home_info_en.headline2}
                </ChakraUI.Text>
                <ChakraUI.Text fontSize={"25px"}>
                    {home_info_en.description}
                </ChakraUI.Text>
            </ChakraUI.Center>

            <ChakraUI.Center>
                <ChakraUI.Button
                    colorScheme={"none"}
                    bgColor={theme.light_green}
                    color={"black"}
                    borderRadius={"10px"}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    px={"30px"}
                    py={"25px"}
                    fontSize={"20px"}
                    fontWeight={"bold"}
                    onClick={() => window.open(call_to_action.url, '_blank')}
                >{call_to_action.title_en}</ChakraUI.Button>
            </ChakraUI.Center>

            <ChakraUI.Center>
                <video
                    width="1200"
                    height="400"
                    autoPlay
                    controls
                    muted
                    style={{borderRadius: "10px", borderWidth: "1px", borderColor: "black"}}
                >
                    <source src={home_info_en.demo_video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </ChakraUI.Center>

        </ChakraUI.Flex>
    );
}

export const HomePageAr: React.FC = () => {
    return (
        <ChakraUI.Flex flexDir={"column"} gap={5} dir={"rtl"} pt={"100px"} >

            <ChakraUI.Center flexDir={"column"} gap={"10px"} textAlign={"center"}>
                <ChakraUI.Text fontSize={"40px"} fontWeight={"bold"}>
                    {home_info_ar.headline1}
                </ChakraUI.Text>
                <ChakraUI.Text fontSize={"30px"} fontWeight={"bold"}>
                    {home_info_ar.headline2}
                </ChakraUI.Text>
                <ChakraUI.Text fontSize={"25px"}>
                    {home_info_ar.description}
                </ChakraUI.Text>
            </ChakraUI.Center>

            <ChakraUI.Center>
                <ChakraUI.Button
                    colorScheme={"none"}
                    bgColor={theme.light_green}
                    color={"black"}
                    borderRadius={"10px"}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    px={"30px"}
                    py={"25px"}
                    fontSize={"20px"}
                    fontWeight={"bold"}
                    onClick={() => window.open(call_to_action.url, '_blank')}
                >{call_to_action.title_ar}</ChakraUI.Button>
            </ChakraUI.Center>

            <ChakraUI.Center>
                <video
                    width="1200"
                    height="400"
                    autoPlay
                    controls
                    muted
                    style={{borderRadius: "10px", borderWidth: "1px", borderColor: "black"}}
                >
                    <source src={home_info_ar.demo_video} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </ChakraUI.Center>

        </ChakraUI.Flex>
    );
}
