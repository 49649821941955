import * as ChakraUI from "@chakra-ui/react";
import {theme} from "../../Utils/Theme";
import {TiThMenu} from "react-icons/ti";
import {PageType, SelectLanguageAction, SelectPageAction} from "../../Data/Page/Actions";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/md";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ThunkDispatch} from "redux-thunk";
import {RootStore} from "../../Data/Store";
import {AnyAction} from "redux";
import {getPage, PAGE, pages} from "../../Utils/Page";
import logo from "../../Assets/logo.svg";
import {useDisclosure} from "@chakra-ui/react";
import {languages, LANGUAGES} from "../../Utils/Types";
import {home_info_ar, home_info_en} from "../../Utils/Info";

export const Header = () => {

    const pageState = useSelector((state: RootStore) => state.pageReducer)

    return (
        <ChakraUI.Flex
            flexDir={"column"}
            px={"20px"}
            pt={"30px"}
            pb={"10px"}
            width={"100%"}
            position={"fixed"}
            bgColor={"white"}
            top={0}
            zIndex={10}
        >
            {pageState.language === LANGUAGES.ENGLISH ? <HeaderEn/> : <HeaderAr/>}
        </ChakraUI.Flex>
    )
}

export const HeaderEn = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const pageState = useSelector((state: RootStore) => state.pageReducer)
    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()

    const onSelectPage = (page: PageType) => {
        onClose()
        dispatch(SelectPageAction(page))
    }

    const onSelectLanguage = (language: string) => {
        dispatch(SelectLanguageAction(language))
    }

    return (
        <ChakraUI.Flex flexDir={"row"} alignItems={"center"} justifyContent={"space-between"}>

            <ChakraUI.Flex flexDir={"row"}
                           alignItems={"center"}
                           gap={"2"} cursor={"pointer"}
                           onClick={() => {
                               const page = getPage(pages, PAGE.HOME)
                               dispatch(SelectPageAction(page))
                           }}
            >
                <ChakraUI.Center
                    w={"40px"}
                    h={"40px"}
                    bgColor={theme.light_green}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    borderRadius={"10px"}
                >
                    <ChakraUI.Image src={logo} w={"50px"} h={"50px"}/>
                </ChakraUI.Center>
                <ChakraUI.Text fontSize={"20px"} fontWeight={"bold"}>{home_info_en.name}</ChakraUI.Text>
            </ChakraUI.Flex>

            <ChakraUI.Flex flexDir={"row"} alignItems={"center"} gap={"3"}>

                <ChakraUI.Flex
                    flexDir={"row"}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    borderRadius={"5px"}
                >
                    {languages.map(language => {
                        return (
                            <ChakraUI.Text
                                key={language}
                                w={"50px"}
                                textAlign={"center"}
                                bgColor={
                                    pageState.language === language
                                        ? theme.light_green
                                        : theme.white
                                }
                                color={theme.black}
                                borderColor={"black"}
                                borderEndWidth={language === LANGUAGES.ENGLISH ? "1px" : ""}
                                borderStartRadius={"5px"}
                                borderEndRadius={language === LANGUAGES.ARABIC ? "5px" : ""}
                                cursor={"pointer"}
                                p={"5px"}
                                onClick={() => onSelectLanguage(language)}
                            >{language}</ChakraUI.Text>
                        )
                    })}
                </ChakraUI.Flex>

                <ChakraUI.Flex>
                    <ChakraUI.Button
                        colorScheme='none'
                        bgColor={theme.light_green}
                        color={"black"}
                        borderWidth={"1px"}
                        borderColor={"black"}
                        borderRadius={"10px"}
                        onClick={onOpen}
                    >
                        <TiThMenu fontSize={"22px"}/>
                    </ChakraUI.Button>
                    <ChakraUI.Drawer
                        isOpen={isOpen}
                        placement='right'
                        onClose={onClose}
                    >
                        <ChakraUI.DrawerOverlay/>
                        <ChakraUI.DrawerContent>
                            <ChakraUI.DrawerCloseButton/>
                            <ChakraUI.DrawerBody>
                                <ChakraUI.Flex flexDir={"column"} alignItems={"center"} gap={"5"} py={"50px"}>
                                    {pages.map((page: PageType) => {
                                        return (
                                            <ChakraUI.Flex
                                                w={"100%"}
                                                flexDir={"row"}
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                                borderWidth={"1px"}
                                                borderRadius={"10px"}
                                                py={"10px"}
                                                px={"15px"}
                                                cursor={"pointer"}
                                                bgColor={pageState.page?.id === page.id
                                                    ? theme.light_green
                                                    : theme.white
                                                }
                                                borderColor={pageState.page?.id === page.id
                                                    ? "black"
                                                    : theme.gray
                                                }
                                                onClick={() => onSelectPage(page)}
                                            >
                                                <ChakraUI.Text noOfLines={1}>{page.name_en}</ChakraUI.Text>
                                                <MdKeyboardArrowRight size={"20px"}/>
                                            </ChakraUI.Flex>
                                        )
                                    })}
                                </ChakraUI.Flex>
                            </ChakraUI.DrawerBody>
                        </ChakraUI.DrawerContent>
                    </ChakraUI.Drawer>
                </ChakraUI.Flex>

            </ChakraUI.Flex>

        </ChakraUI.Flex>
    )
}

export const HeaderAr = () => {

    const {isOpen, onOpen, onClose} = useDisclosure()

    const pageState = useSelector((state: RootStore) => state.pageReducer)
    const dispatch = useDispatch<ThunkDispatch<RootStore, any, AnyAction>>()

    const onSelectPage = (page: PageType) => {
        onClose()
        dispatch(SelectPageAction(page))
    }

    const onSelectLanguage = (language: string) => {
        dispatch(SelectLanguageAction(language))
    }

    return (
        <ChakraUI.Flex flexDir={"row"} alignItems={"center"} justifyContent={"space-between"}>

            <ChakraUI.Flex flexDir={"row"} alignItems={"center"} gap={"3"}>

                <ChakraUI.Flex>
                    <ChakraUI.Button
                        colorScheme='none'
                        bgColor={theme.light_green}
                        color={"black"}
                        borderWidth={"1px"}
                        borderColor={"black"}
                        borderRadius={"10px"}
                        onClick={onOpen}
                    >
                        <TiThMenu fontSize={"20px"}/>
                    </ChakraUI.Button>
                    <ChakraUI.Drawer
                        isOpen={isOpen}
                        placement='left'
                        onClose={onClose}
                    >
                        <ChakraUI.DrawerOverlay/>
                        <ChakraUI.DrawerContent>
                            <ChakraUI.DrawerCloseButton/>
                            <ChakraUI.DrawerBody>
                                <ChakraUI.Flex flexDir={"column"} alignItems={"center"} gap={"5"} py={"50px"}>
                                    {pages.map((page: PageType) => {
                                        return (
                                            <ChakraUI.Flex
                                                w={"100%"}
                                                flexDir={"row"}
                                                justifyContent={"space-between"}
                                                alignItems={"center"}
                                                borderWidth={"1px"}
                                                borderRadius={"10px"}
                                                py={"10px"}
                                                px={"15px"}
                                                cursor={"pointer"}
                                                bgColor={pageState.page?.id === page.id
                                                    ? theme.light_green
                                                    : theme.white
                                                }
                                                borderColor={pageState.page?.id === page.id
                                                    ? "black"
                                                    : theme.gray
                                                }
                                                onClick={() => onSelectPage(page)}
                                            >
                                                <MdKeyboardArrowLeft size={"20px"}/>
                                                <ChakraUI.Text noOfLines={1}>{page.name_ar}</ChakraUI.Text>
                                            </ChakraUI.Flex>
                                        )
                                    })}
                                </ChakraUI.Flex>
                            </ChakraUI.DrawerBody>
                        </ChakraUI.DrawerContent>
                    </ChakraUI.Drawer>
                </ChakraUI.Flex>

                <ChakraUI.Flex
                    flexDir={"row"}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    borderRadius={"5px"}
                >
                    {languages.map(language => {
                        return (
                            <ChakraUI.Text
                                key={language}
                                w={"50px"}
                                textAlign={"center"}
                                bgColor={
                                    pageState.language === language
                                        ? theme.light_green
                                        : theme.white
                                }
                                color={theme.black}
                                borderColor={"black"}
                                borderEndWidth={language === LANGUAGES.ENGLISH ? "1px" : ""}
                                borderStartRadius={"5px"}
                                borderEndRadius={language === LANGUAGES.ARABIC ? "5px" : ""}
                                cursor={"pointer"}
                                p={"5px"}
                                onClick={() => onSelectLanguage(language)}
                            >{language}</ChakraUI.Text>
                        )
                    })}
                </ChakraUI.Flex>

            </ChakraUI.Flex>

            <ChakraUI.Flex flexDir={"row"}
                           alignItems={"center"}
                           gap={"2"}
                           cursor={"pointer"}
                           onClick={() => {
                               const page = getPage(pages, PAGE.HOME)
                               dispatch(SelectPageAction(page))
                           }}
            >

                <ChakraUI.Text fontSize={"20px"} fontWeight={"bold"}>{home_info_ar.name}</ChakraUI.Text>

                <ChakraUI.Center
                    w={"40px"}
                    h={"40px"}
                    bgColor={theme.light_green}
                    borderWidth={"1px"}
                    borderColor={"black"}
                    borderRadius={"10px"}
                >
                    <ChakraUI.Image src={logo} w={"50px"} h={"50px"}/>
                </ChakraUI.Center>

            </ChakraUI.Flex>

        </ChakraUI.Flex>
    )
}