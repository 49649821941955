import {LANGUAGE_SELECT, PAGE_SELECT, PageDispatchTypes, PageType} from "./Actions";
import {getPage, PAGE, pages} from "../../Utils/Page";
import {LANGUAGES} from "../../Utils/Types";


interface PageState {
    page: PageType | null,
    language: string
}

const defaultState: PageState = {
    page: getPage(pages, PAGE.HOME),
    language: LANGUAGES.ARABIC
}

export const PageReducer = (state: PageState = defaultState, action: PageDispatchTypes): PageState => {
    switch (action.type) {
        case PAGE_SELECT:
            return {
                ...state,
                page: action.page
            }
        case LANGUAGE_SELECT:
            return {
                ...state,
                language: action.language
            }
        default:
            return state
    }
}
