import React from 'react';
import * as ChakraUI from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {RootStore} from "./Data/Store";
import {PAGE} from "./Utils/Page";
import {HomePage} from "./Pages/HomePage";
import {ChatPage} from "./Pages/ChatPage";
import {AdGenPage} from "./Pages/AdGenPage";
import {Header} from "./Pages/Components/Header";
import {Footer} from "./Pages/Components/Footer";

const App: React.FC = () => {

    const pageState = useSelector((state: RootStore) => state.pageReducer)

    const renderPage = () => {
        switch (pageState.page?.id) {
            case PAGE.HOME:
                return <HomePage/>
            case PAGE.ADGEN:
                return <AdGenPage/>
            case PAGE.CHAT:
                return <ChatPage/>
            default:
                return <HomePage/>
        }
    }

    return (
        <ChakraUI.Flex flexDir={"column"} w={"100%"} minH={"100vh"}>
            <Header/>
            <ChakraUI.Flex flexDir={"column"} px={"50px"} pb={"100px"} pt={"50px"}>
                {renderPage()}
            </ChakraUI.Flex>
            <Footer/>
        </ChakraUI.Flex>
    );
}

export default App;
