export const PAGE_SELECT = "PAGE_SELECT"
export const LANGUAGE_SELECT = "LANGUAGE_SELECT"

export type PageType = {
    id: string,
    name_en: string,
    name_ar: string,
}

export interface SelectPage {
    type: typeof PAGE_SELECT,
    page: PageType
}

export interface SelectLanguage {
    type: typeof LANGUAGE_SELECT,
    language: string
}

export type PageDispatchTypes = SelectPage | SelectLanguage

export const SelectPageAction = (page: PageType): PageDispatchTypes => {
    return {
        type: PAGE_SELECT,
        page: page,
    }
}

export const SelectLanguageAction = (language: string): PageDispatchTypes => {
    return {
        type: LANGUAGE_SELECT,
        language: language,
    }
}